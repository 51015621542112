import * as React from 'react';
import {Provider } from "mobx-react";
import store from "./components/Store";
import routerStore from "./components/routerStore";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from "./components/home";
import Booking from "./components/booking";
import Name from "./components/name";
import Settings from "./components/settings";
import Welcome from "./components/welcome";


const stores = {
  // Key can be whatever you want
  store: store,
  router: routerStore

};

const App = () => {
  return (
    <Provider {...stores}>
    <Router>
       <div>
          <Route exact path='/' component={Home} />
          <Route path='/booking' component={Booking} />
          <Route path='/name' component={Name} />
          <Route path='/settings' component={Settings} />
          <Route path='/welcome' component={Welcome} />
          </div>
    </Router>
    </Provider>
  );
};
export default App;

import React, { Component } from 'react';
import '../App.css';
import { Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react"
import { Button } from 'react-bootstrap';
import  * as Http from './Http';
import Dates from './dates';
import Header from './header';


class Booking extends Component {
  componentDidMount() {
    const { store} = this.props;
    Http.getDates(store);
  }

  render() {
    const { history, store} = this.props;

 if(!store.name || !store.token){
   return <Redirect to={'/'} />
 }
    return (
      <div className="App">
      <Header title={'Asetukset'} route={'/settings'} store={store}/>

      <div className="name-form" >
        <div className="user-name-container"><span className="user-name">{store.name}</span></div>
        <div className="user-name-button-container"><span className="change-name-button">
          <Button variant="link"  onClick={() => {
            store.changeNameSaved(false);
             history.push("/");
          }} >Nimen vaihto</Button>
          </span>
        </div>
      </div>
      <Dates />
      </div>
    );
  }
}

export default  inject("store")(observer(Booking));

import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react";
import { Redirect } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class Welcome extends Component {

  componentDidMount() {
    document.title = 'HRVaraukset';
    this.props.store.initWelcome();
  }

  render() {
    const store =this.props.store;
    const {history} =this.props;
    if(store.welcome_processed !== undefined && store.welcome_processed ){
       return <Redirect to={{search: this.props.location.search, pathname:'/'}} />
    }
    return (
      <div className="App">
        <header className="App-header">Healing Rooms  Kuopio varaukset</header>
        <div className="welcome-block">
          <span>Tervetuloa varaamaan vuoroja klinkkaan!</span>
        </div>
        <div className="welcome-block">
          <span>Tällä sivulla kannattaa asettaa asettaa sivu selaimeen kirjainmerkiksi tai mobiililaitteen kotinäyttöön, niin pääset jatkossa helposti palveluun!</span>
        </div>
        <div className="welcome-block">
          <span>Kun olet saanut kirjainmerkin talletettua, jatka painamalla painiketta!</span>
        </div>

        <div className="welcome-block">
        <Button variant="primary"  onClick={() => {
          this.props.store.changeWelcomeProcessed();
          history.push('/'+this.props.location.search);
        }} >Jatka</Button>
        </div>
      </div>
    );
  }
}


export default  withRouter(inject("store")(observer(Welcome)));

import axios from 'axios'
import {API_URL} from '../config/config';



const authorize = (store, team, token) => {

    axios.get(API_URL+"/authorize",
          { params: {token: token, team: team}}
      ).then(function (response) {
      // handle success
      if(response.data.authorized){

        store.changeActivity(true);
        if(response.data.admin){

          store.changeIsAdmin(true);
        }
      }
      else{
        store.changeActivity(false);
      }
    })
    .catch(function (error) {
      // handle error
      store.changeActivity(false);
    })
    .then(function () {

    });
}


const getDates = (store) => {
  if(store.token){
    store.changeGettingDates(true);
      axios.get(API_URL+"/dates",
          { params: {token: store.token, team: store.team}}
      ).then(function (response) {
      // handle success
      store.setDates(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      store.changeGettingDates(false);
    });
  }
}

const book = (store, date, dateid, kitchen) => {
  if(store && store.name && store.token ){

      axios.post(API_URL+"/book",
           {token: store.token, name: store.name, date: date, dateid: dateid, team: store.team, kitchen: kitchen? 1:0}
      ).then(function (response) {
      // handle success
      store.setDates(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

}

const remove = (store, date, dateid) => {
  if(store && store.name && store.token ){
      axios.post(API_URL+"/remove",
           {token: store.token, name: store.name, date: date, dateid: dateid, team: store.team}
      ).then(function (response) {
      // handle success
      store.setDates(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }
}

const addDate = (store, date, title) => {
  if(store && store.name && store.token ){
      axios.post(API_URL+"/adddate",
           {token: store.token, date: date, title: title, team: store.team}
      ).then(function (response) {
      // handle success
      store.setDates(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }
}

const removeDate = (store, date, dateid) => {
  if(store && store.name && store.token ){
      axios.post(API_URL+"/removedate",
           {token: store.token, date: date, dateid:dateid, team: store.team}
      ).then(function (response) {
      // handle success
      store.setDates(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }
}

// Export each function
export {
  addDate,
  authorize,
  book,
  getDates,
  remove,
  removeDate

}

import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react";
import { withRouter } from 'react-router-dom';


class Header extends Component {



  render() {
    const { history, store, title, route} = this.props;
    let view;
    if(store.is_admin){
      view = <div className="settings-link" ><span onClick={() => {
      history.push(route);
    }}>{title}</span></div>;
    }
    return (
      <div className="App">
        <header className="App-header">Healing Rooms  Kuopio varaukset</header>
          {view}
      </div>
    );
  }
}


export default  withRouter(inject("store")(observer(Header)));

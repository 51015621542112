import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react"

import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import DateBookings  from './dateBooking';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
class Dates extends Component {



  render() {
    const { store} = this.props;
    var itemsTrough = <div>Ei vapaita päiviä</div>;
    var info=  <div>Ei vapaita päiviä</div>;
    if(store.getting_dates){
      info = <Loader
                 type="Puff"
                 color="#00BFFF"
                 height={100}
                 width={100}
                 timeout={3000} //3 secs

              />;
    }
    else{
      if(!store.dates || !store.dates.length){
        info=  <div>Ei tehtäviä varattavaksi</div>;
      }
      else{
        info= '';
      }
    }
    if(store.dates){
      const items = store.dates;
      itemsTrough = items.map((date) =>
         <div key={date.id} className="single-date">
         <div className="date-title"><Moment format="D.M.YYYY">{date.date}</Moment> <span>{date.title}</span></div>
         <DateBookings date={date.date} dateid={date.id} reservations={date.reservations} />
         </div>
     );
    }


    return (
      <div>
        {info}
        <div className="App-dates">{itemsTrough}</div>
      </div>
    );

  }
}

export default  withRouter(inject("store")(observer(Dates)));

import React from 'react';
import '../App.css';
import { Button } from 'react-bootstrap';
import {inject,observer} from "mobx-react"
import {  useHistory, } from 'react-router-dom';


function NameField(props)  {
  const store =props.store;
  let history = useHistory();


 function handleChange(e) {
  store.changeName(e.target.value);
  }

  function _handleKeyDown(e) {
    if (e.key === 'Enter') {
      store.changeNameSaved(true);
       history.push("/booking");
    }
  }

  return (
    <div>
      <form className="name-form" >
        <label>
          <span>Kirjoita etunimesi:</span>
          <input type="text" value={store.name? store.name: ""} onChange={handleChange} onKeyDown={_handleKeyDown}/>
        </label>
        <Button variant="primary"  onClick={() => {
          store.changeNameSaved(true);
           history.push("/booking");
        }} >Tallenna</Button>
      </form>
    </div>
  );

}

export default  inject("store")(observer(NameField));

import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react";
import { Redirect } from "react-router-dom";
import Header from './header';
import Moment from 'react-moment';
import moment from 'moment/moment.js'
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import  {addDate, removeDate} from './Http';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button } from 'react-bootstrap';

class Settings extends Component {

  constructor(props){
   super(props);
   this.state = {
     date: new Date(),
     title: ''
   }
 }

  handleChange = ({ target }) => {
      let value = target.value;

    this.setState({ [target.name]: value });
  };

  onDateChange = date => {
  //  let value = moment(date).format('YYYY-MM-DD');
    this.setState({ date });

  }

  onTitleChange = title => {
    this.setState({date: this.state.date, title:title});
  };

  saveDate = () => {
      const { store} = this.props;
      const title = this.state.title;
      const date = this.state.date;
    let datestring = moment(date).format('YYYY-MM-DD');
    addDate(store, datestring, title);
  };

  removeSingleDate = (datestring, dateid) => {
    const { store} = this.props;
  confirmAlert({
    title: 'Päivän poistaminen',
    message: 'Haluatko varamasti poistaa päivän?',
    buttons: [
      {
        label: 'Kyllä',
        onClick: () => {   removeDate(store, datestring, dateid);}
      },
      {
        label: 'Ei',
        onClick: () => { }
      }
    ]
  });
};

  render() {
    const { store} = this.props;
    if(!store.active){
      return <Redirect to={'/'} />
    }

    var itemsTrough = <div>Ei asettu päiviä</div>;
    if(store.dates){
      const items = store.dates;
      itemsTrough = items.map((date) =>
         <li key={date.id} className="single-date-list-item">
         <Moment format="D.M.YYYY">{date.date}</Moment><span className="margin-left-right-10">{date.title}</span>
         <Button variant="danger" size="sm" onClick={() => {
          this.removeSingleDate(date.date, date.id);
         }}>X</Button>
         </li>
     );
    }
    return (
      <div className="App">
        <Header title={'Varaukset'} route={'/booking'}/>
        <div className="padding-10">
          <span><b>Asetetut päivät</b></span>
          <ul className="no-bullets">{itemsTrough}</ul>
        </div>
        <div className="padding-10">
          <span> <b>Lisää päivä</b></span>
          <div><DatePicker
            minDate={new Date()}
            onChange={this.onDateChange}
            value={this.state.date}
          />
          </div>
          <form className="name-form" >
            <label>
              <span>Otsikko</span>
              <input type="text" name="title" value={this.state.title} onChange={this.handleChange} />
            </label>
            <Button variant="primary"  onClick={this.saveDate} >Tallenna</Button>
            </form>
        </div>

      </div>
    );
  }
}


export default  withRouter(inject("store")(observer(Settings)));


import { action, decorate } from "mobx";
import {  withRouter } from 'react-router-dom';



class RouterStore{

   goTo = (path) => {
    this.props.history.push(path);
  };

}

decorate(RouterStore, {
  goTo: action
});

export default withRouter(RouterStore);

import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react";
import queryString from 'query-string';
import { Redirect } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

class Home extends Component {

  async componentDidMount() {
    document.title = 'HRVaraukset';
    const store =this.props.store;
    if(!store.active){
      const store =this.props.store;
      const values = queryString.parse(this.props.location.search);
      await store.init(values);
    }
  }


  render() {
    const store =this.props.store;
    let view;

      if(store.active){
            if(store.welcome_processed !== undefined && !store.welcome_processed ){
               return <Redirect to={{search: this.props.location.search, pathname:'/welcome'}} />
            }
        if(store.name && store.name_saved){
           return <Redirect to={'/booking'} />
        }
        else{
          return <Redirect to={'/name'} />
        }


    } else {
      if(!store.inited){
      view =  <Loader
                 type="Puff"
                 color="#00BFFF"
                 height={100}
                 width={100}
                 timeout={3000} //3 secs

              />;
      }
      else{
          view = <div className="name-form" ><span>Ei oikeutta</span></div>;
      }

    }
    return (
      <div className="App">
        <header className="App-header">Healing Rooms  Kuopio varaukset</header>
          {view}
      </div>
    );
  }
}


export default  inject("store")(observer(Home));

import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react"
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SingleBooking  from './singleBooking';
import  {book, remove} from './Http';

class DateBookings extends React.Component {



  render() {
    const { store, date, reservations, dateid} = this.props;
    var itemsTrough = <div className="no-bookings">Ei varauksia</div>;

    var bookbutton = <Button variant="primary"  onClick={() => {

      book(store,date, dateid, this.refs.kitchen? this.refs.kitchen.checked:false);
    }} >Ilmoittaudu</Button>;
    var kitchenCheckBox = <span className="margin-left-10"><input type="checkbox" defaultChecked={false} ref="kitchen" /> Pullavuoro</span>;
    var bookingAllowed=true;
    var showKitchen=false;
    if(reservations){
      itemsTrough = reservations.map((booking) =>
         <div key={date+'_'+booking.name} className="single-booking">
         <SingleBooking booking={booking}  />
         </div>
     );


      for (var i = 0; i < reservations.length && bookingAllowed; i++) {
        var singleBooker = reservations[i];
        if(singleBooker.name.toLowerCase() === store.name.toLowerCase()){
          bookingAllowed = false;
        }
        if(singleBooker.kitchen){
          showKitchen = false;
        }
      }
    }
    if(!bookingAllowed){
      bookbutton =  <Button variant="danger"  onClick={() => {
          remove(store,date, dateid);
      }} >Poista ilmoittautuminen</Button>;
      kitchenCheckBox = <span className="margin-left-10"></span>;
    }
    if(!showKitchen){
      kitchenCheckBox = <span className="margin-left-10"></span>;
    }


    return (
      <div>
      <div className="bookings-container">
      {itemsTrough}
      </div>
      {bookbutton}
      {kitchenCheckBox}
      </div>
    );

  }
}

export default  withRouter(inject("store")(observer(DateBookings)));

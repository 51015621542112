import React, { Component } from 'react';
import '../App.css';
import NameField from './namefield';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react";
import { Redirect } from "react-router-dom";


class Name extends Component {

  render() {
    const store =this.props.store;
    let view;
      if(store.active){
        if(store.name && store.name_saved){
           return <Redirect to={'/booking'} />
        }
        else{
            view = <NameField />;
        }

    } else {
      view = <div className="name-form" ><span>Ei oikeutta</span></div>;
    }
    return (
      <div className="App">
        <header className="App-header">Healing Rooms  Kuopio varaukset</header>
          {view}
      </div>
    );
  }
}


export default  inject("store")(observer(Name));

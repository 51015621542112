import { Component } from 'react';
import { observable, action, decorate } from "mobx";
import {authorize} from './Http';


class Store extends Component{
  name : null;
  name_saved : false;
 display_name : null;
 active : false;
 token : null;
 team : null;
 dates: null;
 is_admin:false;
 getting_dates:false;
 welcome_processed:true;


 init = async (values) => {

  this.initWelcome();
  this.initTeam(values);
  this.initToken(values);
  this.initName();

  if(this.team && this.token){
    // check the token from api -> when done -> store.active will be true
    await authorize(this, this.team, this.token);
  }

 };

 initTeam = (values) => {

   let team = values.team ? values.team : localStorage.getItem('team') ;

   if(team){
     this.changeTeam(team);
   }
   else{
     localStorage.removeItem('team');
   }

 };

 initToken = (values) => {

   let token = values.token && values.token.length ?   values.token : localStorage.getItem('token');

   if(token){
     this.changeToken(token);
   }
   else{
     this.token = null;
     localStorage.removeItem('token');
   }

 };

initName = () => {
 if(!this.name || !this.name_saved){
   let name = localStorage.getItem('name');
   if(name){
     this.changeName(name);
     this.changeNameSaved(true);
   }

 }
};

initWelcome = () => {

  if( localStorage.getItem('welcome_processed')  ){
    this.welcome_processed = true;
  }
  else {
    this.welcome_processed = false;
  }

};


changeName = (name) => {
  this.name = name;
  this.display_name= name;
};

changeGettingDates= (getting_dates) => {
  this.getting_dates = getting_dates;
};

changeNameSaved = (name_saved) => {
  this.name = this.name.trim();
  this.name_saved = name_saved;
  localStorage.setItem('name', this.name);
};

changeActivity= (activity) => {
  this.active = activity;
};

changeToken= (token) => {
  this.token = token;
  if(this.token){
    localStorage.setItem('token', this.token);
  }

};
changeTeam= (team) => {
  this.team = team;
  localStorage.setItem('team', this.team);
};

setDates= (dates) => {
  this.dates = dates;
};

changeIsAdmin= (admin) => {
  this.is_admin = admin;
};

changeWelcomeProcessed = () => {
  this.welcome_processed = true;
  localStorage.setItem('welcome_processed', true);
};

}

decorate(Store, {
  active: observable,
  getting_dates: observable,
  is_admin: observable,
  name: observable,
  name_saved: observable,
  dates: observable,
  display_name: observable,
  token: observable,
  team: observable,
  welcome_processed: observable,
  changeName: action,
  changeNameSaved: action,
  changeActivity: action,
  changeTeam: action,
  changeToken: action,
  changeIsAdmin: action,
  changeGettingDates: action,
  changeWelcomeProcessed: action,
  init:action,
  initWelcome:action
});

export default new Store();
//export default  inject("store")(observer(Home));

import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {inject, observer} from "mobx-react"
import { withRouter } from 'react-router-dom';

class SingleBooking extends React.Component {

  render() {
    const { store, booking} = this.props;

    var highlightClass = "light-text";
    if(booking.name.toLowerCase() === store.name.toLowerCase()){
      highlightClass = "bolded-text";
    }
    var kitchen = <span></span>
    if(booking.kitchen)
      kitchen = <span className="margin-left-10 green-text">Pullavuoro</span>


    return (
      <div className="">
      <span className={highlightClass}>{booking.name}{kitchen}</span>
      </div>
    );

  }
}

export default  withRouter(inject("store")(observer(SingleBooking)));
